import React from 'react'
import HoverIcon from '@/components/HoverIcon'

const SVG = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2710_252454)">
      <path
        d="M13 0C5.82075 0 0 5.82075 0 13C0 19.0916 4.19467 24.1887 9.84967 25.5992C9.789 25.4237 9.75 25.22 9.75 24.9676V22.7457C9.22242 22.7457 8.33842 22.7457 8.11633 22.7457C7.22692 22.7457 6.43608 22.3632 6.05258 21.6526C5.62683 20.8628 5.55317 19.6549 4.498 18.9161C4.18492 18.6702 4.42325 18.3896 4.784 18.4275C5.45025 18.616 6.00275 19.0732 6.52275 19.7513C7.04058 20.4306 7.28433 20.5844 8.25175 20.5844C8.72083 20.5844 9.42283 20.5573 10.0837 20.4533C10.439 19.5509 11.0533 18.72 11.804 18.3278C7.475 17.8826 5.40908 15.7289 5.40908 12.805C5.40908 11.5462 5.94533 10.3285 6.85642 9.30258C6.55742 8.28425 6.1815 6.2075 6.97125 5.41667C8.91908 5.41667 10.0967 6.67983 10.3794 7.02108C11.3501 6.6885 12.4161 6.5 13.5363 6.5C14.6586 6.5 15.7289 6.6885 16.7017 7.02325C16.9813 6.68417 18.1599 5.41667 20.1121 5.41667C20.9051 6.20858 20.5248 8.294 20.2226 9.31017C21.1283 10.3339 21.6613 11.5483 21.6613 12.805C21.6613 15.7267 19.5986 17.8793 15.2761 18.3267C16.4656 18.9475 17.3333 20.6917 17.3333 22.0057V24.9676C17.3333 25.0802 17.3084 25.1615 17.2954 25.2579C22.3611 23.4823 26 18.6723 26 13C26 5.82075 20.1793 0 13 0Z"
        fill="#F2F3F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2710_252454">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const SVG_HOVER = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2735_256606)">
      <path
        d="M13 0C5.82075 0 0 5.82075 0 13C0 19.0916 4.19467 24.1887 9.84967 25.5992C9.789 25.4237 9.75 25.22 9.75 24.9676V22.7457C9.22242 22.7457 8.33842 22.7457 8.11633 22.7457C7.22692 22.7457 6.43608 22.3632 6.05258 21.6526C5.62683 20.8628 5.55317 19.6549 4.498 18.9161C4.18492 18.6702 4.42325 18.3896 4.784 18.4275C5.45025 18.616 6.00275 19.0732 6.52275 19.7513C7.04058 20.4306 7.28433 20.5844 8.25175 20.5844C8.72083 20.5844 9.42283 20.5573 10.0837 20.4533C10.439 19.5509 11.0533 18.72 11.804 18.3278C7.475 17.8826 5.40908 15.7289 5.40908 12.805C5.40908 11.5462 5.94533 10.3285 6.85642 9.30258C6.55742 8.28425 6.1815 6.2075 6.97125 5.41667C8.91908 5.41667 10.0967 6.67983 10.3794 7.02108C11.3501 6.6885 12.4161 6.5 13.5363 6.5C14.6586 6.5 15.7289 6.6885 16.7017 7.02325C16.9813 6.68417 18.1599 5.41667 20.1121 5.41667C20.9051 6.20858 20.5248 8.294 20.2226 9.31017C21.1283 10.3339 21.6613 11.5483 21.6613 12.805C21.6613 15.7267 19.5986 17.8793 15.2761 18.3267C16.4656 18.9475 17.3333 20.6917 17.3333 22.0057V24.9676C17.3333 25.0802 17.3084 25.1615 17.2954 25.2579C22.3611 23.4823 26 18.6723 26 13C26 5.82075 20.1793 0 13 0Z"
        fill="url(#paint0_linear_2735_256606)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2735_256606" x1="-3.26975e-07" y1="25.5992" x2="30.5316" y2="17.8468" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE3DCE" />
        <stop offset="1" stopColor="#94E3FF" />
      </linearGradient>
      <clipPath id="clip0_2735_256606">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Github = props => {
  const { href } = props
  return <HoverIcon defaultIcon={SVG} hoverIcon={SVG_HOVER} href={href} />
}

export default Github
