import React from 'react'
import HoverIcon from '@/components/HoverIcon'

const SVG = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2384_259341)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM21.2576 8.18877C21.3746 8.22512 21.4772 8.29734 21.5506 8.39513C21.6241 8.49291 21.6647 8.61127 21.6666 8.73334C21.6685 8.85542 21.6317 8.97498 21.5613 9.07499C20.9435 9.95576 20.1848 10.7297 19.3154 11.3662C19.2065 13.0239 18.6786 14.6274 17.7808 16.0278C16.8829 17.4282 15.6441 18.58 14.1795 19.3764C12.7149 20.1728 11.0718 20.588 9.40294 20.5833C7.73404 20.5786 6.09338 20.1543 4.63328 19.3497C4.52101 19.2876 4.43247 19.1903 4.3814 19.073C4.33034 18.9557 4.31961 18.8249 4.35088 18.7009C4.38215 18.577 4.45367 18.4667 4.55434 18.3873C4.65501 18.3079 4.77919 18.2639 4.9076 18.2619C6.33297 18.2421 7.7316 17.8739 8.98046 17.1897C7.49628 15.9771 6.39759 14.3617 5.81769 12.5396C5.2378 10.7176 5.20164 8.76711 5.7136 6.92499C5.74356 6.81741 5.80408 6.72073 5.88789 6.64654C5.9717 6.57236 6.07523 6.52384 6.18607 6.5068C6.2969 6.48976 6.41032 6.50492 6.51271 6.55047C6.6151 6.59602 6.7021 6.67002 6.76327 6.76359C7.39371 7.73276 8.21215 8.56691 9.17062 9.21716C10.1291 9.86742 11.2083 10.3207 12.345 10.5504C12.3647 9.82065 12.6144 9.11554 13.0589 8.53487C13.5034 7.9542 14.1201 7.5274 14.8217 7.31487C15.5233 7.10234 16.2743 7.11485 16.9683 7.35063C17.6624 7.58641 18.2644 8.03352 18.6891 8.62866C19.4413 8.57842 20.184 8.43188 20.8986 8.19268C21.0148 8.15378 21.1405 8.15241 21.2576 8.18877Z"
        fill="#F2F3F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2384_259341">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const SVG_HOVER = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2735_256589)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM21.2576 8.18877C21.3746 8.22512 21.4772 8.29734 21.5506 8.39513C21.6241 8.49291 21.6647 8.61127 21.6666 8.73334C21.6685 8.85542 21.6317 8.97498 21.5613 9.07499C20.9435 9.95576 20.1848 10.7297 19.3154 11.3662C19.2065 13.0239 18.6786 14.6274 17.7808 16.0278C16.8829 17.4282 15.6441 18.58 14.1795 19.3764C12.7149 20.1728 11.0718 20.588 9.40294 20.5833C7.73404 20.5786 6.09338 20.1543 4.63328 19.3497C4.52101 19.2876 4.43247 19.1903 4.3814 19.073C4.33034 18.9557 4.31961 18.8249 4.35088 18.7009C4.38215 18.577 4.45367 18.4667 4.55434 18.3873C4.65501 18.3079 4.77919 18.2639 4.9076 18.2619C6.33297 18.2421 7.7316 17.8739 8.98046 17.1897C7.49628 15.9771 6.39759 14.3617 5.81769 12.5396C5.2378 10.7176 5.20164 8.76711 5.7136 6.92499C5.74356 6.81741 5.80408 6.72073 5.88789 6.64654C5.9717 6.57236 6.07523 6.52384 6.18607 6.5068C6.2969 6.48976 6.41032 6.50492 6.51271 6.55047C6.6151 6.59602 6.7021 6.67002 6.76327 6.76359C7.39371 7.73276 8.21215 8.56691 9.17062 9.21716C10.1291 9.86742 11.2083 10.3207 12.345 10.5504C12.3647 9.82065 12.6144 9.11554 13.0589 8.53487C13.5034 7.9542 14.1201 7.5274 14.8217 7.31487C15.5233 7.10234 16.2743 7.11485 16.9683 7.35063C17.6624 7.58641 18.2644 8.03352 18.6891 8.62866C19.4413 8.57842 20.184 8.43188 20.8986 8.19268C21.0148 8.15378 21.1405 8.15241 21.2576 8.18877Z"
        fill="url(#paint0_linear_2735_256589)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2735_256589" x1="-3.26975e-07" y1="26" x2="30.5882" y2="18.3529" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE3DCE" />
        <stop offset="1" stopColor="#94E3FF" />
      </linearGradient>
      <clipPath id="clip0_2735_256589">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Twitter = props => {
  const { href } = props
  return <HoverIcon defaultIcon={SVG} hoverIcon={SVG_HOVER} href={href} />
}

export default Twitter
