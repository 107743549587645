import React from 'react'
import HoverIcon from '@/components/HoverIcon'

const SVG = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2384_259333)">
      <path d="M10.0134 13.9439L16.7084 19.8345L19.6577 6.98074L10.0134 13.9439Z" fill="#F2F3F4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM19.9846 5.21759C20.2178 5.25621 20.4362 5.3573 20.6165 5.51011C20.795 5.66499 20.9279 5.8656 21.0009 6.09036C21.0739 6.31512 21.0843 6.55553 21.0309 6.78574L17.9759 20.127C17.9255 20.3462 17.8188 20.5484 17.6664 20.7137C17.5139 20.879 17.3209 21.0017 17.1065 21.0695C16.9761 21.1153 16.8385 21.1373 16.7002 21.1345C16.3859 21.1329 16.0828 21.0174 15.8471 20.8095L12.654 17.9982L10.5415 20.1026C10.4215 20.2241 10.2786 20.3205 10.1209 20.3861C9.96325 20.4516 9.79411 20.4851 9.62337 20.4845C9.45349 20.4835 9.28534 20.4504 9.12774 20.387C8.88962 20.2888 8.68604 20.1221 8.54283 19.908C8.39961 19.6939 8.32323 19.4421 8.32337 19.1845V14.4232L4.51274 13.6676C4.23707 13.6125 3.98671 13.4694 3.7992 13.2599C3.61169 13.0504 3.49715 12.7858 3.47274 12.5057C3.44539 12.2256 3.51089 11.9441 3.65918 11.7048C3.80746 11.4655 4.03029 11.2816 4.29337 11.1814L19.2921 5.29074C19.5121 5.20424 19.7514 5.17896 19.9846 5.21759Z"
        fill="#F2F3F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2384_259333">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const SVG_HOVER = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2735_256581)">
      <path d="M10.0134 13.9439L16.7084 19.8345L19.6577 6.98074L10.0134 13.9439Z" fill="url(#paint0_linear_2735_256581)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM19.9846 5.21759C20.2178 5.25621 20.4362 5.3573 20.6165 5.51011C20.795 5.66499 20.9279 5.8656 21.0009 6.09036C21.0739 6.31512 21.0843 6.55553 21.0309 6.78574L17.9759 20.127C17.9255 20.3462 17.8188 20.5484 17.6664 20.7137C17.5139 20.879 17.3209 21.0017 17.1065 21.0695C16.9761 21.1153 16.8385 21.1373 16.7002 21.1345C16.3859 21.1329 16.0829 21.0174 15.8471 20.8095L12.654 17.9982L10.5415 20.1026C10.4215 20.2241 10.2786 20.3205 10.1209 20.3861C9.96325 20.4516 9.79411 20.4851 9.62337 20.4845C9.45349 20.4835 9.28534 20.4504 9.12774 20.387C8.88962 20.2888 8.68604 20.1221 8.54283 19.908C8.39961 19.6939 8.32323 19.4421 8.32337 19.1845V14.4232L4.51274 13.6676C4.23707 13.6125 3.98671 13.4694 3.7992 13.2599C3.61169 13.0504 3.49715 12.7858 3.47274 12.5057C3.44539 12.2256 3.51089 11.9441 3.65918 11.7048C3.80746 11.4655 4.03029 11.2816 4.29337 11.1814L19.2921 5.29074C19.5121 5.20424 19.7514 5.17896 19.9846 5.21759Z"
        fill="url(#paint1_linear_2735_256581)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2735_256581" x1="-3.26975e-07" y1="26" x2="30.5882" y2="18.3529" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE3DCE" />
        <stop offset="1" stopColor="#94E3FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_2735_256581" x1="-3.26975e-07" y1="26" x2="30.5882" y2="18.3529" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE3DCE" />
        <stop offset="1" stopColor="#94E3FF" />
      </linearGradient>
      <clipPath id="clip0_2735_256581">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Telegram = props => {
  const { href } = props
  return <HoverIcon defaultIcon={SVG} hoverIcon={SVG_HOVER} href={href} />
}

export default Telegram
