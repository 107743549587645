import React from 'react'
import HoverIcon from '@/components/HoverIcon'

const SVG = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2384_259353)">
      <path
        d="M12.0664 15.9759L15.942 13.5124C16.0305 13.4562 16.1031 13.38 16.1532 13.2906C16.2034 13.2012 16.2297 13.1013 16.2297 13C16.2297 12.8986 16.2034 12.7987 16.1532 12.7093C16.1031 12.62 16.0305 12.5438 15.942 12.4875L12.0664 10.024C11.9691 9.96214 11.8561 9.92664 11.7393 9.92126C11.6225 9.91588 11.5064 9.94082 11.4033 9.99342C11.3003 10.046 11.2141 10.1243 11.154 10.2199C11.0939 10.3156 11.0622 10.4249 11.0622 10.5364V15.4635C11.0622 15.575 11.0939 15.6844 11.154 15.78C11.2141 15.8756 11.3003 15.9539 11.4033 16.0065C11.5064 16.0591 11.6225 16.084 11.7393 16.0786C11.8561 16.0733 11.9691 16.0378 12.0664 15.9759Z"
        fill="#F2F3F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM19.2065 6.38817C20.2176 6.49861 21.1314 7.33132 21.29 8.28424C21.792 11.4087 21.7922 14.5877 21.2906 17.7122C21.1312 18.6688 20.2175 19.5015 19.2098 19.6114L19.2065 19.6117C17.1453 19.8261 15.0734 19.9335 13 19.9333C10.9266 19.9335 8.85471 19.8261 6.79352 19.6117C5.78259 19.5014 4.86884 18.6687 4.70996 17.7158C4.20799 14.5913 4.20779 11.4122 4.70937 8.2877C4.86888 7.3312 5.78259 6.49857 6.79013 6.3885C10.9167 5.9595 15.0799 5.95939 19.2065 6.38817Z"
        fill="#F2F3F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2384_259353">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const SVG_HOVER = (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2735_256598)">
      <path
        d="M12.0664 15.9759L15.942 13.5124C16.0305 13.4562 16.1031 13.38 16.1532 13.2906C16.2034 13.2012 16.2297 13.1013 16.2297 13C16.2297 12.8986 16.2034 12.7987 16.1532 12.7093C16.1031 12.62 16.0305 12.5438 15.942 12.4875L12.0664 10.024C11.9691 9.96214 11.8561 9.92664 11.7393 9.92126C11.6225 9.91588 11.5064 9.94082 11.4033 9.99342C11.3003 10.046 11.2141 10.1243 11.154 10.2199C11.0939 10.3156 11.0622 10.4249 11.0622 10.5364V15.4635C11.0622 15.575 11.0939 15.6844 11.154 15.78C11.2141 15.8756 11.3003 15.9539 11.4033 16.0065C11.5064 16.0591 11.6225 16.084 11.7393 16.0786C11.8561 16.0733 11.9691 16.0378 12.0664 15.9759Z"
        fill="url(#paint0_linear_2735_256598)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0ZM19.2065 6.38817C20.2176 6.49861 21.1314 7.33132 21.29 8.28424C21.792 11.4087 21.7922 14.5877 21.2906 17.7122C21.1312 18.6688 20.2175 19.5015 19.2098 19.6114L19.2065 19.6117C17.1453 19.8261 15.0734 19.9335 13 19.9333C10.9266 19.9335 8.85471 19.8261 6.79352 19.6117C5.78259 19.5014 4.86884 18.6687 4.70996 17.7158C4.20799 14.5913 4.20779 11.4122 4.70937 8.2877C4.86888 7.3312 5.78259 6.49857 6.79013 6.3885C10.9167 5.9595 15.0799 5.95939 19.2065 6.38817Z"
        fill="url(#paint1_linear_2735_256598)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2735_256598" x1="-3.26975e-07" y1="26" x2="30.5882" y2="18.3529" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE3DCE" />
        <stop offset="1" stopColor="#94E3FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_2735_256598" x1="-3.26975e-07" y1="26" x2="30.5882" y2="18.3529" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE3DCE" />
        <stop offset="1" stopColor="#94E3FF" />
      </linearGradient>
      <clipPath id="clip0_2735_256598">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const Youtube = props => {
  const { href } = props
  return <HoverIcon defaultIcon={SVG} hoverIcon={SVG_HOVER} href={href} />
}

export default Youtube
